import { Link as LinkPrimitive } from '@radix-ui/themes'
import React from 'react'
import { cn } from '../../../utils/misc'

const ThemedLink = React.forwardRef<
	React.ElementRef<typeof LinkPrimitive>,
	React.ComponentPropsWithoutRef<typeof LinkPrimitive> & {
		to?: string
	}
>((props, forwardedRef) => {
	return (
		<LinkPrimitive
			href={props.href || props.to}
			ref={forwardedRef}
			{...props}
			className={cn(
				props.className,
				'font-bold text-[var(--accent-5)] hover:text-[var(--accent-9)]',
			)}
		>
			{props.children}
		</LinkPrimitive>
	)
})
ThemedLink.displayName = LinkPrimitive.displayName

export { ThemedLink }
